// src/components/ProjectsSection.js

import React from 'react';
import ProjectCard from './ProjectCard';

const projects = [
  
  {
    title: 'Inshaa Visual and digital identity design',
    description: 'Inshaa is a group of major industrial companies in Syria, with a long history in the construction and mechanical industries. The visual identity has been updated, and a website for the company has been developed.',
    imageUrl: './images/inshaa.jpg',
    priUrl: 'https://inshaa-ind.com/',
  },
  ,
  {
    title: 'Funnel Online Store ',
    description: 'Designing an online store, compatible with the Stripe payment gateway, to sell Fanol to private subscribers, and obtain custom designs,',
    imageUrl: './images/HFLogo.png',
    priUrl: 'https://app.funnelhackerlab.com/',
  },
  ,
  {
    title: 'Al-Aliaa Group Website',
    description: 'Al-Aliaa Company is a proactive leader in the airline reservation industry, serving clients in Iraq, the Gulf States, and the Arab World since 1997. With an official license granted by the Iraqi Ministry of Tourism (License No. 196), we proudly represent esteemed airlines including Iraqi Airways, MEA Airlines, Emirates Airlines, and more',
    imageUrl: './images/aliaaweb.png',
    priUrl: 'https://al-aliaa.iq',
  }
  ,

];

const ProjectsSection = () => {
  return (
    <div className="container mx-auto py-8">
      <h2 className="text-3xl font-bold mb-4 text-cneter p-3"> Projects By Our Engineers </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 ">
        {projects.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </div>
    </div>
  );
};

export default ProjectsSection;